import React from 'react'

const Home = () => {
  return (
    <div>
        <nav>
            Home
        </nav>
    </div>
  )
}

export default Home